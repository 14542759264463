import React from "react"
import Header from "./header"
import Footer from "./footer"
import "../styles/layout.css"

export default ({ children }) => (
  <div id="layout-container">
    <Header />
    {children}
    <Footer id="footer"/>
  </div>
)