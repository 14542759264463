import React from "react"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import style from "./header.module.css"
import Row from 'react-bootstrap/Row'

export default () => (
  <Container>
    <Row className={style.titlerow}>
      <h2 className={style.title}>TRI CHU</h2>
    </Row>
    <div className={style.navrow}>
        <Navbar className={style.navbar} expand="md">
          <Navbar.Toggle aria-controls="navbar-header-main" />
          <Navbar.Brand href="/">
            <p className={style.subtitle}>TRI CHU</p>
          </Navbar.Brand>
          <Navbar.Collapse id="navbar-header-main" style={{justifyContent: "center"}}>
            <Nav>
              <Nav className="mr-auto"></Nav>
              <Nav.Item>
                <Nav.Link href="/">About</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/blog">Blog</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/skills">Skills</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
    </div>
  </Container>
)
