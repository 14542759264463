import React from "react"
import moment from "moment"
import { FaGithubAlt, FaLinkedinIn, FaKeybase } from "react-icons/fa"
import { AiOutlineMail } from "react-icons/ai"

import Container from "react-bootstrap/Container"
import LinkButton from "./linkButton.js"

export default () => (
  <footer>
    <Container>
      <div className="row">
        <div className="col-6">
          <div className="footer-list">
            <LinkButton to="https://github.com/tri-chu/">
              <FaGithubAlt size="1rem" />
            </LinkButton>
            <LinkButton to="https://www.linkedin.com/in/tri-chu/">
              <FaLinkedinIn size="1rem" />
            </LinkButton>
            <LinkButton to="mailto:me@chuminhtri.dev">
              <AiOutlineMail size="1rem" />
            </LinkButton>
          </div>
        </div>
        <div className="col-6">
          <p id="copy-right">
            Copyright © {moment().format("YYYY")}
          </p>
        </div>
      </div>
    </Container>
  </footer>
)
