import React from "react"
import Container from "react-bootstrap/Container"
import {mainContainer} from "./article.module.css"

export default ({ children }) => (
<div className={mainContainer}>
  <Container>
    <article>
      <div>{children}</div>
    </article>
  </Container>
</div>
)